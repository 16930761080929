import React, { Component } from 'react';

class Group extends Component {
  render() {
    return (
      <div className="fm-max">
        <p className="fm-max-title">Group Rides</p>
        <p className="fm-max-text">You can unlock up to 5 scooters at once on the Flamingo app. Simply tap the "Add Ride" button after unlocking your first scooter.</p>
        <p className="fm-max-text">Unlocking fees and per minute rates apply to each scooter that is unlocked.</p>
        <p className="fm-max-text">You can add and remove scooters as you please throughout your Group Ride. To end your rides, tap "End Rides", then select the rides you wish to end.</p>
        <p className="fm-max-end">Have any questions? Visit the support section of our app for more information!</p>
      </div>
    );
  }
}

export default Group;
