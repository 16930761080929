import React, { Component } from 'react';

class PalmyPrice extends Component {
  render() {
    return (
      <div className="fm-max">
        <p className="fm-max-title">Updated Pricing</p>
        <p className="fm-max-text">Kia ora Palmy,</p>
        <p className="fm-max-text">From Thursday 2nd February our standard pricing has increased from $0.45c per minute to $0.55c per minute, while our $1 unlocking fee remains the same. This change aligns with rising costs across the transport industry and enables us to continue to offer you a fun, safe and easy way to get around Palmerston North.</p>
        <p className="fm-max-text">We're proud to continue to offer Flamingo Go Passes which provide great savings on e-scooter rides. These are subscription plans which waive unlock fees and allow you to ride for up to 45 minutes per day.</p>
        <img className="fm-max-photo" alt="Go Pass Pricing" src="https://storage.googleapis.com/flamingo-static/images/support/gopassprice.jpg" />
        <p className="fm-max-text">Check out the “Go Pass” section of the Flamingo app for more info!</p>
        <p className="fm-max-end">Our Community/Student Plans are also increasing from $0.30c per minute to $0.40c per minute, while there remains no unlocking fee. You can apply for these plans with a valid Student ID, Community Services Card or SuperGold Card from the "Payment" section of the Flamingo app.</p>
        <br />
      </div>
    );
  }
}

export default PalmyPrice;
