import React, { Component } from 'react';
import moment from 'moment';

class Dunedin extends Component {
  constructor(props) {
    super(props);
    const status = moment().isBefore('2024-07-01 00:00:00') ? 'pre' : (moment().isBefore('2024-07-05 15:00:00') ? 'event' : 'normal');
    this.state = { referralCode: false, status };
    this.fetchReferralCode = this.fetchReferralCode.bind(this);
  }

  componentDidMount() {
    this.fetchReferralCode();
  }

  fetchReferralCode() {
    const uuid = window.location.pathname.split('/').pop();
    if (uuid.length === 36) {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ uuid })
      };
      return fetch('https://production.api.flamingoscooters.com/promo/referral', options)
        .then(response => response.json())
        .then(data => this.setState({ referralCode: data.data.referralCode }))
        .catch(error => console.error(error));
    }
  }

  render() {
    const { referralCode, status } = this.state;
    const intro = {
      pre: `Flamingo is coming to Dunedin! We're excited to have you ride with us from the 1st of July, so heres a few things you should know...`,
      event: `Flamingo has landed in Dunedin! We're excited to have you ride with us, so heres a few things you should know...`,
      normal: `Flamingo has landed in Dunedin! We're excited to have you ride with us, so heres a few things you should know...`,
    };
    return (
      <div className="fm-max">
        <p className="fm-max-title">Dunedin</p>
        <p className="fm-max-text">{ intro[status] }</p>
        <div className="fm-max-item">
          <img className="fm-max-item-img" alt="Scooter" src="/assets/images/city/scooter.svg" />
          <div className="fm-max-item-details">
            <p className="fm-max-item-title">How to Ride</p>
            <p className="fm-max-item-text">You can find instructions on how to ride our scooters and the rules for Dunedin from the "How to Ride" tab from the side menu of the app.</p>
          </div>
        </div>
        <div className="fm-max-item">
          <img className="fm-max-item-img" alt="Fares" src="/assets/images/city/flamingo.svg" />
          <div className="fm-max-item-details">
            <p className="fm-max-item-title">Simple Fares</p>
            <p className="fm-max-item-text">Our scooters cost $1 to unlock, then just 45c per minute.</p>
          </div>
        </div>
        <div className="fm-max-item">
          <img className="fm-max-item-img" alt="Scooter" src="/assets/images/city/student.svg" />
          <div className="fm-max-item-details">
            <p className="fm-max-item-title">Student Discounts</p>
            <p className="fm-max-item-text">Pay no unlocking fees and just 45c per minute! Just submit your student ID from the "Payment" tab from the side menu of the app.</p>
          </div>
        </div>
        <div className="fm-max-item">
          <img className="fm-max-item-img" alt="Scooter" src="/assets/images/city/group.svg" />
          <div className="fm-max-item-details">
            <p className="fm-max-item-title">Group Rides</p>
            <p className="fm-max-item-text">You can unlock up to 5 scooters at once on the Flamingo app. Simply tap the "Add Ride" button after unlocking your first scooter.</p>
          </div>
        </div>
        <div className="fm-max-item">
          <img className="fm-max-item-img" alt="Scooter" src="/assets/images/city/discount.svg" />
          <div className="fm-max-item-details">
            <p className="fm-max-item-title">Community Discounts</p>
            <p className="fm-max-item-text">Have a Community Services Card, SuperGold Card or Total Mobility Card? Save 50% on rides! Visit the "Payment" tab from the side menu of the app for more info.</p>
          </div>
        </div>
        <div className="fm-max-item">
          <img className="fm-max-item-img" alt="Go Pass" src="/assets/images/city/go-pass.svg" />
          <div className="fm-max-item-details">
            <p className="fm-max-item-title">Save with Go Pass</p>
            <p className="fm-max-item-text">With subscriptions starting at $2.99 per day you can save up to 86% on rides! All subscriptions include 45 mins per day and no unlocking fees.</p>
          </div>
        </div>
        <div className="fm-max-item">
          <img className="fm-max-item-img" alt="Scooter" src="/assets/images/city/enviro.svg" />
          <div className="fm-max-item-details">
            <p className="fm-max-item-title">Zero Carbon</p>
            <p className="fm-max-item-text">Ride easy knowing Flamingo is Carbon Neutral certified!</p>
          </div>
        </div>
        <div className="fm-max-item">
          <img className="fm-max-item-img" alt="Scooter" src="/assets/images/city/parking.svg" />
          <div className="fm-max-item-details">
            <p className="fm-max-item-title">Parking Discounts</p>
            <p className="fm-max-item-text">End your ride at locations with the blue parking icon 🅿️ on the map and earn free credit for your next ride!</p>
          </div>
        </div>
        <div className="fm-max-item">
          <img className="fm-max-item-img" alt="Scooter" src="/assets/images/city/kiwi.svg" />
          <div className="fm-max-item-details">
            <p className="fm-max-item-title">100% Kiwi Owned</p>
            <p className="fm-max-item-text">Flamingo is proudly 100% Kiwi owned and operated! Thanks for supporting local!</p>
          </div>
        </div>
        <div className="fm-max-item">
          <img className="fm-max-item-img" alt="Map" src="/assets/images/city/map.svg" />
          <div className="fm-max-item-details">
            <p className="fm-max-item-title">Service Area</p>
            <p className="fm-max-item-text">Make sure to only ride and park within our service area. This is outlined in pink within our app and below.</p>
          </div>
        </div>
        <br />
        <img className="fm-max-photo" alt="Service Area" src="/assets/images/city/dunedin.png" />
        {
          (referralCode && ['pre', 'normal'].includes(status)) ? (
            <div className="fm-max-ref">
              <p className="fm-max-ref-text">Want to earn some <b>FREE</b> credit? Refer your friends using your unique code below and you'll each get <b>5 mins free</b>!</p>
              <p className="fm-max-ref-code">{ referralCode }</p>
              <p className="fm-max-ref-note">Redeem the referral code as a voucher from the "Payment" section of the Flamingo app.</p>
            </div>
          ) : (
            <div className="fm-max-ref">
              <p className="fm-max-ref-text">To celebrate the launch of Flamingo in Dunedin we will be running a FREE Learn to Ride!</p>
              <br />
              <p className="fm-max-ref-note">Come on down to The Octagon from <b>1pm to 4pm on Friday the 5th of July</b> and learn to ride and park our Flamingo scooters for FREE!</p>
              <br/>
              <p className="fm-max-ref-note">Plus, enjoy a <b>FREE coffee or hot chocolate</b> from Hot Shot Coffee! The first 100 people with the Flamingo app downloaded will receive a complimentary drink.</p>
            </div>
          )
        }
        <p className="fm-max-end">If you have any questions, feel free to flick us an email at <a href="mailto:hello@flamingo.co.nz">hello@flamingo.co.nz</a></p>
      </div>
    );
  }
}

export default Dunedin;
