import React, { Component } from 'react';

class Contact extends Component {
  render() {
    return (
      <div className="fm-container">
        <p className="fm-webview-text">Tap the button below, or call <a href="tel:0800352646" style={{ color: '#0000ff' }}>0800 FLAMINGO</a> (0800 352 646) to talk to one of our representatives.</p>
      </div>
    );
  }
}

export default Contact;
