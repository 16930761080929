import React, { Component } from 'react';

class FreeHelmet extends Component {
  render() {
    return (
      <div className="fm-container">
        <p className="fm-webview-text">You can order a free helmet and only pay for shipping by tapping the “Helmets” tab from within the app.</p>
      </div>
    );
  }
}

export default FreeHelmet;
