import React, { Component } from 'react';

class HowStart extends Component {
  render() {
    return (
      <div className="fm-container">
        <p className="fm-webview-text">To start a ride, tap “Ride” and scan the QR code or enter the code. Once unlocked, make sure to put the kickstand down. Manually kick-push the scooter like you would a non-electric scooter to start moving and then press down on the throttle. Start off slowly while you get used to the accelerator and brakes. Ride safe!</p>
      </div>
    );
  }
}

export default HowStart;
