import React, { Component } from 'react';

class HowCost extends Component {
  render() {
    return (
      <div className="fm-container">
        <p className="fm-webview-text">To see the cost of a Flamingo, tap on the Flamingo marker on the map. A pop-up will appear with the pricing. Our prices vary in different cities.</p>
      </div>
    );
  }
}

export default HowCost;
