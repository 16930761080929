import React, { Component } from 'react';

class WherePark extends Component {
  render() {
    return (
      <div className="fm-container">
        <p className="fm-webview-text">You can park your scooter anywhere within the service area. Please park to the side of the footpath out of the way of pedestrians and without blocking the walkway or an entrance. The best parking spots are next to street furniture such as bike racks. Remember to use the kickstand. You are required to take a photo of your parked scooter before you end your ride. You can expect to receive incentives such as discounts on your next ride if you demonstrate good parking behaviour.</p>
      </div>
    );
  }
}

export default WherePark;
