import React, { Component } from 'react';

class RideRain extends Component {
  render() {
    return (
      <div className="fm-container">
        <p className="fm-webview-text">Yes, Flamingos are rainproof but remember to take extra care when riding on wet surfaces. In the event of extreme weather, Flamingos will be remotely disabled and unavailable for riding.</p>
      </div>
    );
  }
}

export default RideRain;
