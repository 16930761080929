import React, { Component } from 'react';

class MaxChch extends Component {
  render() {
    return (
      <div className="fm-max">
        <div className="fm-max-image" style={{ backgroundImage: `url('/assets/images/wellington-max.jpg')` }}></div>
        <p className="fm-max-title">New Flamingo Scooters</p>
        <p className="fm-max-text">Before you ride there are a few things you need to know...</p>
        <div className="fm-max-item">
          <img className="fm-max-item-img" alt="Max Braking" src="https://storage.googleapis.com/flamingo-static/images/support/max-brake.png" />
          <div className="fm-max-item-text-box">
          <p className="fm-max-item-title">Always Test Brakes Before Riding</p>
          <p className="fm-max-item-text">Our new scooters have a manual <b>front</b> brake. Make sure you test this before riding and apply it carefully.</p>
        </div>
        </div>
        <div className="fm-max-item">
          <img className="fm-max-item-img" alt="Kick Started" src="https://storage.googleapis.com/flamingo-static/images/support/max-kick-started.png" />
          <div className="fm-max-item-text-box">
          <p className="fm-max-item-title">Kick Started</p>
          <p className="fm-max-item-text">Kick off and reach 5km/h before pressing Go.</p>
        </div>
        </div>
        <div className="fm-max-item">
          <img className="fm-max-item-img" alt="No Step" src="https://storage.googleapis.com/flamingo-static/images/training/plus-no-step.png" />
          <div className="fm-max-item-text-box">
            <p className="fm-max-item-title">Foot Placement</p>
            <p className="fm-max-item-text">Keep both feet on the board for a safe ride. <b>Do not stand on the rear fender</b>.</p>
          </div>
        </div>
        <div className="fm-max-item">
          <img className="fm-max-item-img" alt="Low Speed Zones" src="https://storage.googleapis.com/flamingo-static/images/support/low-speed.png" />
          <div className="fm-max-item-text-box">
          <p className="fm-max-item-title">Low Speed Zones</p>
          <p className="fm-max-item-text">Look out for the orange Low Speed Zones in the Flamingo app. You can only go 15km/h in these areas.</p>
        </div>
        </div>
        <div className="fm-max-item">
          <img className="fm-max-item-img" alt="Footpath Safety" src="https://storage.googleapis.com/flamingo-static/images/support/ped.png" />
          <div className="fm-max-item-text-box">
          <p className="fm-max-item-title">Footpath Safety</p>
          <p className="fm-max-item-text">Travel at a safe speed and give way to pedestrians and cars.</p>
        </div>
        </div>
        <div className="fm-max-item">
          <img className="fm-max-item-img" alt="One Person" src="https://storage.googleapis.com/flamingo-static/images/support/single.png" />
          <div className="fm-max-item-text-box">
          <p className="fm-max-item-title">One Person Per Scooter</p>
          <p className="fm-max-item-text">Only one person per scooter!</p>
        </div>
        </div>
        <div className="fm-max-item">
          <img className="fm-max-item-img" alt="Helmet" src="https://storage.googleapis.com/flamingo-static/images/support/helmet.png" />
          <div className="fm-max-item-text-box">
          <p className="fm-max-item-title">Helmet</p>
          <p className="fm-max-item-text">Always wear your helmet! You can get a free helmet through the Flamingo app, just pay for shipping.</p>
          </div>
          </div>
        <p className="fm-max-end">Happy Riding! If you need any more help give us a call on 0800 FLAMINGO.</p>
      </div>
    );
  }
}

export default MaxChch;
