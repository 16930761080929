import React, { Component } from 'react';

class PoriruaYear extends Component {
  render() {
    return (
      <div className="fm-max">
        <img className="fm-max-photo" alt="1 Year Porirua" src="https://storage.googleapis.com/flamingo-static/images/facade/porirua-scooter-2.jpg" />
        <p className="fm-max-title">1 Year in Porirua</p>
        <p className="fm-max-text">Today we're celebrating 1 year of Flamingo in Porirua! 🦩 🛴</p>
        <p className="fm-max-text">Thank you for taking part in the 36,000 trips this past year, as a thank you we're giving you <b>10 minutes of free</b> credit.</p>
        <p className="fm-max-text">Just redeem the code <b>PORIRUAONE</b> from the "Payment" tab of the Flamingo app.</p>
        <p className="fm-max-text">Happy Riding,<br/>The Flamingo Team</p>
        <br />
      </div>
    );
  }
}

export default PoriruaYear;
