import React, { Component } from 'react';

class NZComp extends Component {
  render() {
    return (
      <div className="fm-max">
        <p className="fm-max-title">Win 1,000 FREE Minutes 🏉</p>
        <p className="fm-max-text">Show your support for NZ! 🇳🇿 Hunt down our special edition scooters, follow us on Instagram <a style={{ color: '#ff206e' }} href="https://www.instagram.com/flamingoscooters/">@flamingoscooters</a> and tag us in an Instagram post or story of you scooting on one to go in the draw to win 1000 free Flamingo minutes!</p>
        <img className="fm-max-photo" alt="NZ Flamingo Scooter" src="https://storage.googleapis.com/flamingo-static/images/support/nzscooter.jpg" />
        <p className="fm-max-text">These limited-edition scooters are hidden around Wellington, Palmerston North, Porirua and Waimakariri!</p>
        <p className="fm-max-text">Every story earns you one entry, while a post gets you three. We'll give you a further bonus entry if you're rocking a helmet!</p>
        <p className="fm-max-text">The lucky winner will be drawn on 30th October!</p>
        <p className="fm-max-terms">Competition period open from 00:00 14 September 2023 to 12:00 30 October 2023. Entries are made by making a public Story or Post on Instagram that includes a special edition black Flamingo scooter in the photo. One entry per Instagram account. @flamingoscooters must be tagged in the Story or Post. Winner will be drawn on 30 October 2023. The winning Instagram account must follow @flamingoscooters on Instagram. The winner will be notified via Instagram and will be required to nominate the Flamingo account to receive the prize of 1,000 free Flamingo minutes. $1 unlocking fee still applies to trips. The 1,000 free minutes will be valid for 1 year from 30 October 2023. This prize has no monetary value. Instagram is not affiliated with this promotion.</p>
        <br />
      </div>
    );
  }
}

export default NZComp;
