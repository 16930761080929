import React, { Component } from 'react';

class Braking extends Component {
  render() {
    console.log(this.props);
    return (
      <div className="fm-brake">
        <p className="fm-brake-intro">With winter upon us, its important to have a safe trip. Follow these safety precautions when riding your Flamingo.</p>
        {
          this.props.max ? (
            <>
              <div className="fm-brake-box">
                <div className="fm-brake-photo" style={{ backgroundImage: 'url(/assets/images/braking/es.png)' }}></div>
                <p className="fm-brake-text">Use the left brake lever to slow down and stop on first generation scooters.</p>
              </div>
              <div className="fm-brake-box">
                <div className="fm-brake-photo" style={{ backgroundImage: 'url(/assets/images/braking/back-brake.png)' }}></div>
                <p className="fm-brake-text">Use the rear brake to stop on first generation scooters.</p>
              </div>
              <div className="fm-brake-box">
                <div className="fm-brake-photo" style={{ backgroundImage: 'url(/assets/images/braking/max.png)' }}></div>
                <p className="fm-brake-text">Use the left brake lever to stop on second generation scooters.</p>
              </div>
            </>
          ) : (
            <>
              <div className="fm-brake-box">
                <div className="fm-brake-photo" style={{ backgroundImage: 'url(/assets/images/braking/es.png)' }}></div>
                <p className="fm-brake-text">Use the left brake lever to slow down and stop.</p>
              </div>
              <div className="fm-brake-box">
                <div className="fm-brake-photo" style={{ backgroundImage: 'url(/assets/images/braking/back-brake.png)' }}></div>
                <p className="fm-brake-text">Use the rear brake to stop.</p>
              </div>
            </>
          )
        }
        <div className="fm-brake-box">
          <div className="fm-brake-photo" style={{ backgroundImage: 'url(/assets/images/braking/test.png)' }}></div>
          <p className="fm-brake-text">Always test the brakes before riding.</p>
        </div>
        <div className="fm-brake-box">
          <div className="fm-brake-photo" style={{ backgroundImage: 'url(/assets/images/braking/rain.png)' }}></div>
          <p className="fm-brake-text">Ride to the conditions, take extra care on wet surfaces.</p>
        </div>
        <div className="fm-brake-box">
          <div className="fm-brake-photo" style={{ backgroundImage: 'url(/assets/images/braking/hill.png)' }}></div>
          <p className="fm-brake-text">Take extreme caution down steep hills, avoid if possible.</p>
        </div>
        <div className="fm-brake-box">
          <div className="fm-brake-photo" style={{ backgroundImage: 'url(/assets/images/braking/safe.png)' }}></div>
          <p className="fm-brake-text">Maintain a safe speed and give way to pedestrians and vehicles.</p>
        </div>
      </div>
    );
  }
}

export default Braking;
