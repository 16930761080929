import React, { Component } from 'react';

class HowFar extends Component {
  render() {
    return (
      <div className="fm-container">
        <p className="fm-webview-text">Flamingo operates a service area where all scooters must be returned to at the end of your ride. This area is shaded in pink on your map.</p>
      </div>
    );
  }
}

export default HowFar;
