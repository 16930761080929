import React, { Component } from 'react';

class HelmetSelfie extends Component {
  render() {
    return (
      <div className="fm-max">
        <div className="fm-max-image" style={{ backgroundImage: `url('https://storage.googleapis.com/flamingo-static/images/support/helmet-selfie.png')` }}></div>
        <p className="fm-max-text">With Flamingo Helmet Selfies, you earn free credit every time you wear a helmet while riding a Flamingo.</p>
        <p className="fm-max-text">After you start your ride, a helmet icon will show in the bottom right-hand corner of the map. Simply tap this button and take a selfie photo which clearly shows yourself wearing a helmet.</p>
        <p className="fm-max-text">These photos will then be reviewed by our incredible customer support team and you will receive 3 free minutes for every ride you take a helmet selfie on! The minimum ride time is also 3 minutes.</p>
        <p className="fm-max-text">To celebrate, enter the below code into the Payment tab on the Flamingo app for 5 minutes off your next ride!</p>
        <p className="fm-max-code">SELFIE5</p>
        <p className="fm-max-end">Happy Riding,<br/>The Flamingo Team</p>
      </div>
    );
  }
}

export default HelmetSelfie;
