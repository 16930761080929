import React, { Component } from 'react';

class NewtownFestival extends Component {
  render() {
    return (
      <div className="fm-max">
        <p className="fm-max-title">Newtown Festival 2024</p>
        <p className="fm-max-text">The Newtown Festival is on Sunday the 3rd of March, this means there are a few changes to where you can use Flamingo in Newtown.</p>
        <p className="fm-max-text">For health and safety reasons, <b>you cannot ride or park your scooter/bike any where inside the festival</b>. These restrictions are in place <b>all day Sunday</b>.</p>
        <p className="fm-max-text">This area is highlighted on the map below, and in the Flamingo app:</p>
        <img className="fm-max-photo" alt="Newtown Festival" src="https://storage.googleapis.com/flamingo-static/images/support/flamingo-newtown-zones.png" />
        <p className="fm-max-text"><b>Heading to the Newtown Festival?</b> Use the Newtown cycleway when travelling to/from the city, then park your Flamingo at the Designated Parking Area 🅿️ inside Newtown School (access from Mein St).</p>
        <p className="fm-max-text"><b>At the festival?</b> Visit the Flamingo stall at the end of Green St near St Anne's School to get some <b>FREE credit!</b></p>
        <p className="fm-max-end">Happy Riding,<br />The Flamingo Team</p>
        <br />
      </div>
    );
  }
}

export default NewtownFestival;
