import React, { Component } from 'react';

class ReportParking extends Component {
  render() {
    return (
      <div className="fm-container">
        <p className="fm-webview-text">If you find a scooter that is poorly parked, please report it by tapping the exclamation mark in the bottom left hand corner of the map and selecting “Bad Parking”. Feel free to help out by setting the Flamingo upright or relocating it to a more appropriate parking spot.</p>
      </div>
    );
  }
}

export default ReportParking;
