import React, { Component } from 'react';

class SafetyGuidelines extends Component {
  render() {
    return (
      <div className="fm-container">
        <ul className="fm-webview-list">
          <li className="fm-webview-text">Wear a helmet.</li>
          <li className="fm-webview-text">Ride considerably.</li>
          <li className="fm-webview-text">Don’t drink and ride.</li>
          <li className="fm-webview-text">Only one person per scooter.</li>
          <li className="fm-webview-text">Before riding, do a quick inspection of the scooter. If you see any signs of damage, do not ride the scooter and instead report the problem by tapping the exclamation mark in the bottom left hand corner of the map. Then choose another nearby scooter to ride!</li>
          <li className="fm-webview-text">Follow all traffic rules including street signs and stop signs.</li>
          <li className="fm-webview-text">Use caution at pedestrian crossings.</li>
          <li className="fm-webview-text">Always be aware of surrounding traffic, especially at intersections - cars are your biggest risk.</li>
          <li className="fm-webview-text">Start off slowly while you get used to the accelerator and brakes.</li>
          <li className="fm-webview-text">Ride with two hands at all times. Put down the phone and coffee cup!</li>
          <li className="fm-webview-text">No headphones - listen to what’s around you.</li>
        </ul>
      </div>
    );
  }
}

export default SafetyGuidelines;
