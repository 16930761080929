import React, { Component } from 'react';

class WgtnGo extends Component {
  render() {
    return (
      <div className="fm-max">
        <p className="fm-max-title">Flamingo Go Pass</p>
        <p className="fm-max-text">We're excited to announce Flamingo Go Passes! These are subscription plans which waive unlock fees and allow you to ride for up to 45 minutes per day. Plans cost as low as $2.33 per day!</p>
        <img className="fm-max-photo" alt="Go Pass Wellington" src="https://storage.googleapis.com/flamingo-static/images/support/go-pass-wgtn.png" />
        <p className="fm-max-text">From today, you will find ‘Go Pass’ in the side tab menu of the Flamingo app.</p>
        <p className="fm-max-text">We're proud to now be offering Go Passes to provide great savings on e-scooter rides. In fact, you can save up to 87% with the above plans!</p>
        <p className="fm-max-end">Thanks for supporting a Kiwi owned and operated company!</p>
      </div>
    );
  }
}

export default WgtnGo;
