import React, { Component } from 'react';

class TaranakiWharf extends Component {
  render() {
    return (
      <div className="fm-max">
        <p className="fm-max-title">Hinemoana Ka Eke</p>
        <p className="fm-max-text">The FIFA World Cup opening event Hinemoana Ka Eke will be taking place on the Wellington Waterfront between <b>3pm and 7pm on Thursday 20th July 2023</b>, this means there are a few changes to where you can use Flamingo.</p>
        <img className="fm-max-photo" alt="Event Area" src="https://storage.googleapis.com/flamingo-static/images/support/taranaki-wharf.jpg" />
        <p className="fm-max-text">For health and safety reasons, <b>you cannot ride or park your scooter/bike anywhere on the waterfront between the Aukati Bridge (Whairepo Lagoon) and the Waitangi Park Bridge (Barnett Street)</b>. These restrictions are in place from 3pm to 7pm.</p>
        <p className="fm-max-text"><b>Parking:</b> There are a number of temporary parking areas set up around Hinemoana Ka Eke, just look for the blue [ P ] icon in the Flamingo app.</p>
        <p className="fm-max-text"><b>Alternative Route:</b> Please refer to the green line on the map above for an alternative route to avoid Hinemoana Ka Eke restrictions.</p>
        <p className="fm-max-text">Heading to Hinemoana Ka Eke? Get <b>10 minutes FREE</b>, just enter the code <b>FIFAWELLY</b> from the "Payment" tab of the Flamingo app.</p>
        <p className="fm-max-end">Happy Riding,<br />The Flamingo Team</p>
        <br />
      </div>
    );
  }
}

export default TaranakiWharf;
