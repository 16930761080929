import React, { Component } from 'react';

class Bike extends Component {
  render() {
    return (
      <div className="fm-max">
        <div className="fm-max-image" style={{ backgroundImage: `url('https://storage.googleapis.com/flamingo-static/images/facade/how-to-bike.jpg')` }}></div>
        <p className="fm-max-title">Flamingo Bikes</p>
        <p className="fm-max-text">We are thrilled to be launching our Flamingo Bikes in Wellington! The Flamingo Bike is electric assisted meaning the onboard intelligent system automatically applies power as you pedal based on the cycling enviroment.</p>
        <p className="fm-max-text">Our bikes have a top assisted speed of 32 km/h and are priced the same as our scooters. Bikes are also now included in our Flamingo Go Passes!</p>
        <p className="fm-max-text">Here are some quick tips to get you started:</p>
        <div className="fm-how-to-safety-items">
          <div className="fm-how-to-safety-item">
            <div className="fm-how-to-safety-item-image" style={{ backgroundImage: `url('https://storage.googleapis.com/flamingo-static/images/email/bike-unlocking.gif')` }}></div>
            <p className="fm-how-to-safety-item-title">Remove the Lock</p>
            <p className="fm-how-to-safety-item-message">Pull the lock cable out of the hole, then insert it into the lock pocket on top of the rear fender.</p>
          </div>
          <div className="fm-how-to-safety-item">
            <div className="fm-how-to-safety-item-image" style={{ backgroundImage: `url('https://storage.googleapis.com/flamingo-static/images/training/bike-ride.png')` }}></div>
            <p className="fm-how-to-safety-item-title">Where to Ride</p>
            <p className="fm-how-to-safety-item-message">Ride on the road and in designated bike lanes.</p>
          </div>
        	<div className="fm-how-to-safety-item">
        		<div className="fm-how-to-safety-item-image" style={{ backgroundImage: `url('https://storage.googleapis.com/flamingo-static/images/training/bike-park.png')` }}></div>
        		<p className="fm-how-to-safety-item-title">Where to Park</p>
        		<p className="fm-how-to-safety-item-message">Park in bike racks or to the side of the footpath.</p>
        	</div>
        	<div className="fm-how-to-safety-item">
        		<div className="fm-how-to-safety-item-image" style={{ backgroundImage: `url('https://storage.googleapis.com/flamingo-static/images/training/helmet%403x.png')` }}></div>
        		<p className="fm-how-to-safety-item-title">Helmet</p>
        		<p className="fm-how-to-safety-item-message">Always wear a helmet, its required by law.</p>
        	</div>
        	<div className="fm-how-to-safety-item">
        		<div className="fm-how-to-safety-item-image" style={{ backgroundImage: `url('https://storage.googleapis.com/flamingo-static/images/training/bike-start.png')` }}></div>
        		<p className="fm-how-to-safety-item-title">Getting Started</p>
        		<p className="fm-how-to-safety-item-message">Push the bike forward to raise the kickstand, then hop on.</p>
        	</div>
        	<div className="fm-how-to-safety-item">
        		<div className="fm-how-to-safety-item-image" style={{ backgroundImage: `url('https://storage.googleapis.com/flamingo-static/images/training/bike-controls.png')` }}></div>
        		<p className="fm-how-to-safety-item-title">Controls</p>
        		<p className="fm-how-to-safety-item-message">Pedal like a normal bike and the power assist will automatically begin.</p>
        	</div>
        	<div className="fm-how-to-safety-item">
        		<div className="fm-how-to-safety-item-image" style={{ backgroundImage: `url('https://storage.googleapis.com/flamingo-static/images/training/bike-braking.png')` }}></div>
        		<p className="fm-how-to-safety-item-title">Braking</p>
        		<p className="fm-how-to-safety-item-message">Use the left and right brake levers to stop.</p>
        	</div>
        	<div className="fm-how-to-safety-item">
        		<div className="fm-how-to-safety-item-image" style={{ backgroundImage: `url('https://storage.googleapis.com/flamingo-static/images/training/bike-bell.png')` }}></div>
        		<p className="fm-how-to-safety-item-title">Bell</p>
        		<p className="fm-how-to-safety-item-message">Twist the bell with your left hand to alert pedestrians.</p>
        	</div>
        	<div className="fm-how-to-safety-item">
        		<div className="fm-how-to-safety-item-image" style={{ backgroundImage: `url('https://storage.googleapis.com/flamingo-static/images/training/bike-end.png')` }}></div>
        		<p className="fm-how-to-safety-item-title">Ending your Ride</p>
        		<p className="fm-how-to-safety-item-message">Lower the kickstand while rolling the bike backwards to park, then tap “End Ride” within the app.</p>
        	</div>
          <div className="fm-how-to-safety-item">
            <div className="fm-how-to-safety-item-image" style={{ backgroundImage: `url('https://storage.googleapis.com/flamingo-static/images/email/bike-locking.gif')` }}></div>
            <p className="fm-how-to-safety-item-title">Attach the Lock</p>
            <p className="fm-how-to-safety-item-message">Pull the lock pin out of the pocket on the rear fender and insert it into the lock hole.</p>
          </div>
        </div>
        <p className="fm-max-text">The Flamingo Go Pass includes all scooters and bikes across Wellington. The Go Pass waives unlock fees and allows you to ride for up to 45 minutes per day for as low as $2.33 per day. Visit the "Go Pass" tab of the app for more information.</p>
        <p className="fm-max-end">If you need help visit the "How to Ride" section of our app or simply email us at hello@rideflamingo.co.nz.</p>
      </div>
    );
  }
}

export default Bike;
