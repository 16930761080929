import React, { Component } from 'react';

class RideReceipt extends Component {
  render() {
    return (
      <div className="fm-container">
        <p className="fm-webview-text">Please tap into the “History” tab to review past rides.</p>
      </div>
    );
  }
}

export default RideReceipt;
