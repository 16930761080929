import React, { Component } from 'react';

class Contact extends Component {
  render() {
    return (
      <div className="fm-container">
        <p className="fm-webview-text"><b>Catching Flamingos</b></p>
        <p className="fm-webview-text">You will be able to reserve scooters shown in the App and all Flamingos must then be caught within 2 hours. It is your responsibility to collect and charge the scooter once you have reserved it. Please only reserve scooters that you are sure you can pick up because it prevents others (including us!) from catching them. Once you have reserved a scooter, it is your obligation to charge it. You can only cancel a reservation if it is missing, there is a fault or special circumstances prevent you from charging the scooter.</p>
        <p className="fm-webview-text">We monitor this and those who repeatedly fail to charge reserved scooters may have their Feeder account suspended and/or cancelled.</p>
        <p className="fm-webview-text"><b>Catch &amp; Release Windows</b></p>
        <p className="fm-webview-text">Scooters captured between 9am and midnight need to be released between 5am and 7am the next day.</p>
        <p className="fm-webview-text">Scooters captured between midnight and 9am need to be released within 10 hours of being collected.</p>
        <p className="fm-webview-text"><b>Driving to catch Flamingos</b></p>
        <p className="fm-webview-text">Your safety is more important than the collection of a scooter. Be aware of your surroundings at all times. If there is a situation that makes you feel uncomfortable or unsafe, please do not proceed. If a situation escalates outside of your control, retreat immediately to a place of safety. Contact the police and Flamingo.</p>
        <p className="fm-webview-text"><b>Missing Flamingos</b></p>
        <p className="fm-webview-text">If you cannot find a Flamingo, you can ring the bell and listen for it. If you still cannot find the scooter, please mark it as missing.</p>
        <p className="fm-webview-text"><b>Reporting damaged Flamingos</b></p>
        <p className="fm-webview-text">Please give each scooter you collect an inspection and follow the maintenance checklist shown in the app.</p>
        <p className="fm-webview-text">If you arrive at a reserved Flamingo that is damaged and unsafe to charge, please report it from within the app. This will put the scooter into maintenance mode and flag it for our Ops team to pick up. Safety is our top priority so you will still receive full payout for reporting scooters because we want to discourage you from charging damaged scooters.</p>
        <p className="fm-webview-text">If you arrive at a reserved Flamingo that requires maintenance but is still safe to charge (such as a missing bell or wobbly stem), please take it home for charging but then report it instead of releasing it once you have it back out on the street. Please place the scooter off to the side instead of lined up in a nest.</p>
        <p className="fm-webview-text">If you notice a scooter is damaged or an error code shows up once you’ve collected it, you can put it out on the street anywhere within our service zone and report it for us to pick up. The scooter will lock once you report it so please only report a scooter once you have it out on the street in a convenient place for collection by our Ops team. You can do this straight away or the next morning instead of releasing it. You will receive full payout regardless.</p>
        <p className="fm-webview-text">If a scooter starts to beep once you have picked it up, check whether there is an error code showing next to a red spanner on the LED screen. Most error codes require one of our mechanics to take a look so please follow the above reporting process. However, error code 42 and 21 are related to the battery and are sometimes triggered when the battery is completely flat. As long as the external battery (the part you plug the charger into) appears undamaged and is still tightly attached to the stem of the scooter, try to charge the scooter for 5-10 minutes and the scooter should stop beeping. Report it as usual if the error code doesn't disappear.</p>
        <p className="fm-webview-text">It is important to please check that the frame/stem of each scooter is secure and doesn't wobble. This is a common and quick fix for our mechanics so please charge the scooter and then report it instead of releasing it the next morning.</p>
        <p className="fm-webview-text"><b>Riding Flamingos home</b></p>
        <p className="fm-webview-text">You must wear a helmet at all times when riding a Flamingo and follow the same safety rules as riders.</p>
        <p className="fm-webview-text"><b>Feeding Flamingos</b></p>
        <p className="fm-webview-text">Plug the chargers into the wall. Consult a professional electrician in regards to safe charging practices for your facility or household. Do not overload electrical outlets or tamper with chargers or scooters. You must use chargers supplied by the Flamingo scooter manufacturer.</p>
        <p className="fm-webview-text"><b>Releasing Flamingos</b></p>
        <p className="fm-webview-text">Electric scooters must be parked where they do not impede pedestrians or vehicle access. Follow the nest location specified in the app. Tilt the handle bars the way you think balances best for the specific nest but ensure all handle bars are tilted the same way. Space each scooter 20-30cm apart.</p>
        <p className="fm-webview-text">When releasing more than one Flamingo at a nest, please scan all scooters before taking a photo of all scooters together.</p>
        <p className="fm-webview-text"><b>Nests</b></p>
        <p className="fm-webview-text">You will be able to reserve nests for all your scooters once you have collected them.</p>
        <p className="fm-webview-text">Scooters captured between 9am and midnight need to be released between 5am and 7am the next day.</p>
        <p className="fm-webview-text">Christchurch: Scooters captured between midnight and 9am need to be released within 10 hours of being collected.</p>
        <p className="fm-webview-text">Wellington: Scooters captured between midnight and 9am need to be released within 7 hours of being collected.</p>
        <p className="fm-webview-text"><b>Payout Schedule</b></p>
        <p className="fm-webview-text">30min - 1hr late: 10% deduction</p>
        <p className="fm-webview-text">1hr - 2hr late: 20% deduction</p>
        <p className="fm-webview-text">2hr - 3hr late: 30% deduction</p>
        <p className="fm-webview-text">3hr - 4hr late: 40% deduction</p>
        <p className="fm-webview-text">4hr - 8hr late: 50% deduction</p>
        <p className="fm-webview-text">8hr + late: 100% deduction</p>
        <p className="fm-webview-text"><b>Battery Charge Penalties</b></p>
        <p className="fm-webview-text">80-90% charged: 50% deduction</p>
        <p className="fm-webview-text">Less than 80% charged: 100% deduction</p>
      </div>
    );
  }
}

export default Contact;
