import React, { Component } from 'react';

class Contact extends Component {
  render() {
    return (
      <div className="fm-container">
        <p className="fm-webview-text">To close your Flamingo account, please contact our support team and a Flamingo representative will assist you.</p>
      </div>
    );
  }
}

export default Contact;
