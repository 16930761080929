import React, { Component } from 'react';

class Stolen extends Component {
  render() {
    return (
      <div className="fm-container">
        <p className="fm-webview-text">You are responsible from the time the scooter is unlocked until it is locked. You may be accountable for a scooter if you do not lock it after your ride, and the scooter gets stolen as a result.</p>
      </div>
    );
  }
}

export default Stolen;
