import React, { Component } from 'react';

class IncurringCharges extends Component {
  render() {
    return (
      <div className="fm-container">
        <p className="fm-webview-text">When your scooter is still incurring charges:</p>
        <ul className="fm-webview-list">
          <li className="fm-webview-text">Check if you have locked the scooter correctly. Once locked, you will hear a tone.</li>
          <li className="fm-webview-text">Wait at least one minute for the ride to finish processing after locking the scooter.</li>
          <li className="fm-webview-text">Please check whether your phone has good internet connection.</li>
          <li className="fm-webview-text">Try closing and restarting the app.</li>
        </ul>
        <p className="fm-webview-text">If none of the above suggestions resolve the issue, please contact our support team and we will investigate and refund the charge accordingly.</p>
      </div>
    );
  }
}

export default IncurringCharges;
