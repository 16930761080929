import React, { Component } from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import history from './helpers/history';

import Home from './components/home';
import Webview from './components/webview';

class App extends Component {
  render() {
    return (
      <Router history={history}>
        <div className="fm-support">
          <Switch>
            <Route path="/webview/:page/:code?" component={Webview} />
            <Route path="/" component={Home} />
          </Switch>
        </div>
      </Router>
    );
  }
}

export default App;
