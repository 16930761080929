import React, { Component } from 'react';

class FredAgain extends Component {
  render() {
    return (
      <div className="fm-max">
        <p className="fm-max-title">Fred again..</p>
        <p className="fm-max-text">Fred again is performing at Waitangi Park tonight, this means there are a few changes to where you can park your Flamingo.</p>
        <p className="fm-max-text">For health and safety reasons, <b>you cannot park your scooter/bike on the waterfront from Te Papa / Tory St to Herd St</b>. These restrictions are in place from <b>5pm to 11pm Friday</b>.</p>
        <p className="fm-max-text">This area is highlighted on the map below, and in the Flamingo app:</p>
        <img className="fm-max-photo" alt="Fred Again 2024" src="https://storage.googleapis.com/flamingo-static/images/support/fred-again-2024.png" />
        <p className="fm-max-text"><b>Heading to Fred again?</b> Get <b>10 minutes FREE</b>, just enter the code <b>AGAIN</b> from the "Payment" tab of the Flamingo app.</p>
        <p className="fm-max-text"><b>Where to Park?</b> Use the Fred again parking areas labeled with the blue 🅿️ icon in the Flamingo app.</p>
        <p className="fm-max-end">Happy Riding,<br />The Flamingo Team</p>
        <br />
      </div>
    );
  }
}

export default FredAgain;
