import React, { Component } from 'react';

class Covid3 extends Component {
  render() {
    return (
      <div className="fm-max">
        <p className="fm-max-title">COVID-19 Level 3</p>
        <p className="fm-max-text">The Ministry of Transport has authorised our e-scooters remain in service as an essential transport provider. There are a number of additional steps we are taking to ensure the safety of everyone.</p>
        <div className="fm-max-item">
          <img className="fm-max-item-img" alt="Essential" src="/assets/images/covid/essential.svg" />
          <div className="fm-max-item-details">
            <p className="fm-max-item-title">Essential Travel Only</p>
            <p className="fm-max-item-text">Only travel for essential reasons, such as essential personal movement, including going to work or school if you have to.</p>
          </div>
        </div>
        <div className="fm-max-item">
          <img className="fm-max-item-img" alt="Mask" src="/assets/images/covid/mask.svg" />
          <div className="fm-max-item-details">
            <p className="fm-max-item-title">Wear a Face Covering</p>
            <p className="fm-max-item-text">Face coverings are required to be worn in alert level 3 businesses and services. This includes on a scooter.</p>
          </div>
        </div>
        <div className="fm-max-item">
          <img className="fm-max-item-img" alt="Scooter" src="/assets/images/covid/distance.svg" />
          <div className="fm-max-item-details">
            <p className="fm-max-item-title">Social Distancing</p>
            <p className="fm-max-item-text">Follow social distancing guidelines when unlocking and parking scooters near others.</p>
          </div>
        </div>
        <div className="fm-max-item">
          <img className="fm-max-item-img" alt="Scooter" src="/assets/images/covid/wash.svg" />
          <div className="fm-max-item-details">
            <p className="fm-max-item-title">Personal Hygiene</p>
            <p className="fm-max-item-text">Wash hands before and after riding, or use hand sanitiser. Please do not ride a scooter if you are feeling unwell.</p>
          </div>
        </div>
        <div className="fm-max-item">
          <img className="fm-max-item-img" alt="Scooter" src="/assets/images/covid/cleaning.svg" />
          <div className="fm-max-item-details">
            <p className="fm-max-item-title">Enhanced Cleaning</p>
            <p className="fm-max-item-text">Our cleaning procedures have been enhanced with a special focus on high touch areas.</p>
          </div>
        </div>
        <div className="fm-max-item">
          <img className="fm-max-item-img" alt="Scooter" src="/assets/images/covid/scooter.svg" />
          <div className="fm-max-item-details">
            <p className="fm-max-item-title">Sanitisation Tracking</p>
            <p className="fm-max-item-text">Our systems are tracking the cleaning of all scooters to ensure they are fully sanitised at least once a day.</p>
          </div>
        </div>
        <div className="fm-max-item">
          <img className="fm-max-item-img" alt="Scooter" src="/assets/images/covid/person.svg" />
          <div className="fm-max-item-details">
            <p className="fm-max-item-title">Contact Tracing</p>
            <p className="fm-max-item-text">Our software allows contact tracing, in the low chance this is required.</p>
          </div>
        </div>
        <p className="fm-max-end">Please contact us if you have any questions in regards to our ongoing response to COVID-19. Please advise us immediately by calling 0800 FLAMINGO if you ride a scooter and then later test positive for COVID-19</p>
      </div>
    );
  }
}

export default Covid3;
