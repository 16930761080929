import React, { Component } from 'react';

class LowSpeed extends Component {
  render() {
    return (
      <div className="fm-max">
        <p className="fm-max-title">New Low Speed Zone</p>
        <p className="fm-max-text">Parts of Oxford Terrace, Cashel Street and High Street are now a Low Speed Zone. This zone is visible on the Flamingo map as an orange shaded area, like the map below.</p>
        <img className="fm-max-photo" alt="waterfront" src="https://storage.googleapis.com/flamingo-static/images/support/christchurch.png" />
        <p className="fm-max-end">When you enter this zone, your scooter will make 5 short beeps before it automatically reduces your maximum speed to 15 km/h. <br /><br />This Low Speed Zone trial is to increase safety in high foot traffic areas. If you have any questions or feedback, feel free to contact us at <a href="mailto:hello@flamingoscooters.co.nz">hello@flamingoscooters.co.nz</a>.</p>
      </div>
    );
  }
}

export default LowSpeed;
