import React, { Component } from 'react';

class NewtownToSea extends Component {
  render() {
    return (
      <div className="fm-max">
        <img className="fm-max-photo" alt="Learn to Ride" src="https://storage.googleapis.com/flamingo-static/images/support/wellington-ebike-newtown-city.jpg" />
        <p className="fm-max-title">Learn to Ride - Newtown to City Launch</p>
        <p className="fm-max-text">Come on down to Waitangi Park near the corner of Cable St and Oriental Parade from 11am to 3pm on Sunday the 24th of September and learn to ride a Flamingo E-Bike on the new Newtown to City route for FREE! 🦩 🚲</p>
        <p className="fm-max-text">We will also be giving away FREE Flamingo vouchers and t-shirts to anyone with the Flamingo app downloaded. 👕📱</p>
        <br/>
        <img className="fm-max-photo" alt="Map" src="https://storage.googleapis.com/flamingo-static/images/support/wellington-ebike-newtown-city-map.png" />
        <p className="fm-max-text">Find a variety of activities along the route including food trucks and family friendly activities. For more information, visit the Wellington City Council event below:<br/><a href="https://www.facebook.com/events/140600285780060">https://www.facebook.com/events/140600285780060</a></p>
        <br />
      </div>
    );
  }
}

export default NewtownToSea;
