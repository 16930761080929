import React, { Component } from 'react';

class WhatIsFlamingo extends Component {
  render() {
    return (
      <div className="fm-container">
        <p className="fm-webview-text">Flamingo is the new dockless electric scooter-share company from New Zealand. Our mission is to offer people a fun, safe and easy way to get around. Riding a Flamingo is a first and last mile transport option that reduces reliance on cars, eases congestion on roads and helps people get around in an environmentally-friendly way.</p>
      </div>
    );
  }
}

export default WhatIsFlamingo;
