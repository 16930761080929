import React, { Component } from 'react';

class NotOnMap extends Component {
  render() {
    return (
      <div className="fm-container">
        <p className="fm-webview-text">Please ensure you have searched all open public spaces and behind other vehicles or large structures. You can ring the bell to help locate it. If you still cannot find the scooter, please let us know by reporting it as missing. Then choose another nearby scooter to ride!</p>
      </div>
    );
  }
}

export default NotOnMap;
