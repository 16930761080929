import React, { Component } from 'react';

class StudentDiscount extends Component {
  render() {
    return (
      <div className="fm-container">
        <p className="fm-webview-text">Our student discount plan waives the $1 unlock fee, and only costs students 30c per minute to ride. To join this plan, you must be actively enrolled in higher education and supply a valid form of student ID. Please email a photo of your student ID to students@flamingoscooters.com to join this plan.</p>
      </div>
    );
  }
}

export default StudentDiscount;
