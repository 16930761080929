import React, { Component } from 'react';

const compare = { minutes: 45, unlock: 100, perMin: 55 };
const plans = [
  { name: '3-Day', price: 2500, days: 3 },
  { name: 'Weekly', price: 2999, days: 7, pink: true },
  { name: 'Monthy', price: 8999, days: 30 },
];

class WgtnPrice extends Component {
  render() {
    return (
      <div className="fm-max">
        <p className="fm-max-title">Updated Pricing</p>
        <p className="fm-max-text">Kia ora Waimakariri,</p>
        <p className="fm-max-text">From Monday 3nd July our standard pricing has increased from $0.45c per minute to <b>$0.55c per minute</b>, while our <b>$1 unlocking fee</b> remains the same. This change aligns with rising costs across the transport industry and enables us to continue to offer you a fun, safe and easy way to get around Waimakariri.</p>
        <p className="fm-max-text">Our Community/SuperGold/Student Plan pricing has changed to <b>50c to unlock + 27c per minute</b> to continue to offer just over a <b>50% discount</b> on all rides. You can apply for these plans with a valid Student ID, Community Services Card or SuperGold Card from the “Payment” section of the Flamingo app.</p>
        <p className="fm-max-text">We're proud to continue to offer Flamingo Go Passes which provide great savings on e-scooter rides. These are subscription plans which waive unlock fees and allow you to now ride for up to <b>45 minutes</b> per day.</p>
        <div className="fm-go">
          {
            plans.map((plan, i) => {
              const percent = ((1 - plan.price / plan.days / (compare.minutes * compare.perMin + compare.unlock)) * 100).toFixed();
              const className = plan.pink ? 'fm-go-plan fm-go-plan-pink' : 'fm-go-plan';
              return (
                <div className={className} key={i}>
                  <div className="fm-go-plan-top">
                    <p className="fm-go-plan-name">{ plan.name }</p>
                    <p className="fm-go-plan-price">${ (plan.price / plan.days / 100).toFixed(2) }</p>
                    <p className="fm-go-plan-per">per day</p>
                  </div>
                  <div className="fm-go-plan-bottom">
                    <p className="fm-go-plan-savings">SAVE UP TO { percent }%</p>
                  </div>
                </div>
              );
            })
          }
        </div>
        <p className="fm-max-text">Check out the “Go Pass” section of the Flamingo app for more info!</p>
        <br />
      </div>
    );
  }
}

export default WgtnPrice;
