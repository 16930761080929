import React, { Component } from 'react';

class Max extends Component {
  render() {
    return (
      <div className="fm-max">
        <p className="fm-max-title">Porirua</p>
        <p className="fm-max-text">Flamingo has arrived in Porirua! We're excited to have you ride with us, so heres a few things you should know...</p>
        <div className="fm-max-item">
          <img className="fm-max-item-img" alt="Scooter" src="/assets/images/city/scooter.svg" />
          <div className="fm-max-item-details">
            <p className="fm-max-item-title">How to Ride</p>
            <p className="fm-max-item-text">You can find instructions on how to ride our scooters and the rules for Porirua from the "How to Ride" tab from the side menu of the app.</p>
          </div>
        </div>
        <div className="fm-max-item">
          <img className="fm-max-item-img" alt="Go Pass" src="/assets/images/city/go-pass.svg" />
          <div className="fm-max-item-details">
            <p className="fm-max-item-title">Save with Go Pass</p>
            <p className="fm-max-item-text">With subscriptions starting at $2.99 you can save up to 90% on rides! All subscriptions include 45 mins per day and no unlocking fees. Includes rides in both Porirua and Wellington.</p>
          </div>
        </div>
        <div className="fm-max-item">
          <img className="fm-max-item-img" alt="Scooter" src="/assets/images/city/student.svg" />
          <div className="fm-max-item-details">
            <p className="fm-max-item-title">Student Discounts</p>
            <p className="fm-max-item-text">Pay no unlocking fees and just 30c per minute! Just submit your student ID from the "Payment" tab from the side menu of the app.</p>
          </div>
        </div>
        <div className="fm-max-item">
          <img className="fm-max-item-img" alt="Scooter" src="/assets/images/city/group.svg" />
          <div className="fm-max-item-details">
            <p className="fm-max-item-title">Group Rides</p>
            <p className="fm-max-item-text">You can unlock up to 5 scooters at once on the Flamingo app. Simply tap the "Add Ride" button after unlocking your first scooter.</p>
          </div>
        </div>
        <div className="fm-max-item">
          <img className="fm-max-item-img" alt="Scooter" src="/assets/images/city/discount.svg" />
          <div className="fm-max-item-details">
            <p className="fm-max-item-title">Community Discounts</p>
            <p className="fm-max-item-text">Have a Community Services Card or SuperGold Card? Pay no unlocking fees and just 30c per minute! Visit the "Payment" tab from the side menu of the app for more info.</p>
          </div>
        </div>
        <div className="fm-max-item">
          <img className="fm-max-item-img" alt="Scooter" src="/assets/images/city/enviro.svg" />
          <div className="fm-max-item-details">
            <p className="fm-max-item-title">Zero Carbon</p>
            <p className="fm-max-item-text">Flamingo is the only operator with Carbon Neutral certification issued from a New Zealand certification body in Porirua!</p>
          </div>
        </div>
        <div className="fm-max-item">
          <img className="fm-max-item-img" alt="Scooter" src="/assets/images/city/parking.svg" />
          <div className="fm-max-item-details">
            <p className="fm-max-item-title">Parking Discounts</p>
            <p className="fm-max-item-text">End your ride at locations with the blue parking icon on the map and earn 3 minutes off your next ride!</p>
          </div>
        </div>
        <div className="fm-max-item">
          <img className="fm-max-item-img" alt="Scooter" src="/assets/images/city/kiwi.svg" />
          <div className="fm-max-item-details">
            <p className="fm-max-item-title">100% Kiwi Owned</p>
            <p className="fm-max-item-text">Flamingo is proudly 100% Kiwi owned and operated! Thanks for supporting local!</p>
          </div>
        </div>
        <div className="fm-max-item">
          <img className="fm-max-item-img" alt="Map" src="/assets/images/city/map.svg" />
          <div className="fm-max-item-details">
            <p className="fm-max-item-title">Service Area</p>
            <p className="fm-max-item-text">Make sure to only ride and park within our service area. This is outlined in pink within our app and below.</p>
          </div>
        </div>
        <br />
        <img className="fm-max-photo" alt="Service Area" src="/assets/images/city/porirua.png" />
        <p className="fm-max-end">If you have any questions, just give us a call on 0800 FLAMINGO (0800 352 646) or email hello@rideflamingo.co.nz</p>
      </div>
    );
  }
}

export default Max;
