import React, { Component } from 'react';

class RemoveCard extends Component {
  render() {
    return (
      <div className="fm-container">
        <p className="fm-webview-text">You can remove a credit card by tapping the “Payment” tab and swiping the card to the left. You are required to keep at least one payment method on your account.</p>
      </div>
    );
  }
}

export default RemoveCard;
