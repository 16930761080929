import React, { Component } from 'react';

class DragComp extends Component {
  render() {
    return (
      <div className="fm-max2">
        <img className="fm-max-photo" alt="Palmy Drag Fest" src="https://storage.googleapis.com/flamingo-static/images/support/palmy-drag-logo.png" />
        <p className="fm-max-title2">Win 400 FREE Minutes + Double Pass to the Palmy Drag Fest</p>
        <p className="fm-max-text">Flamingo is proud to support Palmy Drag Fest 2024 with 15 specially-designed scooters spread around Palmerston North!</p>
        <p className="fm-max-text">We're giving you the chance to win <b>400 minutes of free Flamingo credit</b> and a <b>double pass</b> to the Palmy Drag Fest on <b>Saturday 5th October</b>.</p>
        <img className="fm-max-photo" alt="Flamingo Palmy Drag Fest Scooters" src="https://storage.googleapis.com/flamingo-static/images/support/palmy-drag.jpg" />
        <p className="fm-max-text"><b>How to enter:</b></p>
        <ol className="fm-max-list2">
          <li className="fm-max-text">Spot one of the specially-designed Flamingo scooters around Palmerston North.</li>
          <li className="fm-max-text">Snap a picture or video with the scooter.</li>
          <li className="fm-max-text">Post it on Instagram as a story or post.</li>
          <li className="fm-max-text">Tag both <a style={{ color: '#ff206e', fontWeight: 600 }} href="https://www.instagram.com/flamingoscooters/">@flamingoscooters</a> and <a style={{ color: '#907acf', fontWeight: 600 }} href="https://www.instagram.com/palmydragfest/">@palmydragfest</a> to be in the draw.</li>
        </ol>
        <p className="fm-max-text">Winners will be announced on the 2nd of October, so get creative and show your support for Palmy Drag Fest!</p>
        <br/>
        <p className="fm-term-title">Competition Terms and Conditions</p>
        <p className="fm-term-item">Eligibility: The competition is open to individuals aged 18 and over, residing in New Zealand. Employees of Flamingo and Palmy Drag Fest, their immediate family members, and anyone professionally connected to the competition are not eligible to enter.</p>
        <p className="fm-term-item">Entry Requirements: To enter, participants must post a photo or video of one of the specially-designed Flamingo scooters (as pictured above) on Instagram and tag both @FlamingoScooters and @PalmyDragFest. The post must be public for the duration of the competition.</p>
        <p className="fm-term-item">Prize: The winner will receive 400 minutes of free Flamingo ride time and a double pass to the Palmy Drag Fest 2024, taking place on Saturday 5th October. The prize is non-transferable and cannot be exchanged for cash.</p>
        <p className="fm-term-item">Competition Period: The competition runs from 6 September 2024 to 1 October 2024. Entries must be submitted by midnight on the closing date.</p>
        <p className="fm-term-item">Winner Selection: The winner will be chosen at random from all eligible entries and announced via Flamingo’s official Instagram account on the 2nd of October 2024. The winner will be contacted via Instagram DM.</p>
        <p className="fm-term-item">Claiming the Prize: The winner must respond to Flamingo within 24 hours of being notified. If the winner does not claim the prize within 24 hours, Flamingo reserves the right to select another winner.</p>
        <p className="fm-term-item">Content Usage: By entering the competition, participants agree that Flamingo and Palmy Drag Fest can use their entry content for promotional purposes across their social media and marketing platforms.</p>
        <p className="fm-term-item">Changes to the Competition: Flamingo reserves the right to modify, suspend, or cancel the competition if necessary, due to unforeseen circumstances beyond its control.</p>
        <p className="fm-term-item">Agreement to Terms: By participating in the competition, participants agree to abide by these terms and conditions.</p>
      </div>
    );
  }
}

export default DragComp;
