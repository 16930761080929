import React, { Component } from 'react';

class HowFast extends Component {
  render() {
    return (
      <div className="fm-container">
        <p className="fm-webview-text">The top speed of our scooters is 25km/h. The speed of the scooters will depend on the area in which you are trying to ride. You can see any Low Speed zones on your map.</p>
      </div>
    );
  }
}

export default HowFast;
