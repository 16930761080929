import React, { Component } from 'react';

class PoriruaEvent extends Component {
  render() {
    return (
      <div className="fm-max">
        <img className="fm-max-photo" alt="Learn to Ride" src="https://storage.googleapis.com/flamingo-static/images/support/porirua%20scooter-2.jpg" />
        <p className="fm-max-title">Learn to Ride</p>
        <p className="fm-max-text">Come on down to Cobham Court in Porirua from 11am to 2pm on Saturday the 18th of November and learn to ride and park our Flamingo scooters for FREE! 🦩 🛴</p>
        <p className="fm-max-text">We will also be giving away FREE Flamingo credit and SOCKS to anyone with the Flamingo app downloaded. 🧦 📱</p>
        <br />
        <a className="fm-max-text" href="https://www.facebook.com/events/887870942971576/">View on Facebook</a>
      </div>
    );
  }
}

export default PoriruaEvent;
