import React, { Component } from 'react';

class Sky extends Component {
  render() {
    return (
      <div className="fm-max">
        <p className="fm-max-title">All Blacks v Australia</p>
        <p className="fm-max-text">Heading to Sky Stadium for the All Blacks v Australia? There are a few restrictions to where you can ride and park your Flamingo at Sky Stadium.</p>
        <img className="fm-max-photo" alt="Event Area" src="https://storage.googleapis.com/flamingo-static/images/support/fifa.jpg" />
        <p className="fm-max-text">For health and safety reasons, <b>you cannot ride or park your scooter/bike anywhere on the stadium concourse</b>. These restrictions are in place from 3:30pm to 7:15pm.</p>
        <p className="fm-max-text"><b>Parking:</b> There are a number of temporary parking areas set up on Thorndon Quay, Waterloo Quay and at Wellington Railway Station. Please use these locations when travelling to the stadium, just look for the blue 🅿️ icon in the Flamingo app.</p>
        <p className="fm-max-text">Get <b>10 minutes FREE</b>, just enter the code <b>NZSTRONG</b> from the "Payment" tab of the Flamingo app.</p>
        <p className="fm-max-end">Happy Riding,<br />The Flamingo Team</p>
        <br />
      </div>
    );
  }
}

export default Sky;
