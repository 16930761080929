import React, { Component } from 'react';

class ReportDangerous extends Component {
  render() {
    return (
      <div className="fm-container">
        <p className="fm-webview-text">Please tap the exclamation mark in the bottom left hand corner of the map and select “Bad Riding” or call 0800 FLAMINGO to speak with a Flamingo representative.</p>
      </div>
    );
  }
}

export default ReportDangerous;
