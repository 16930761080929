import React, { Component } from 'react';

class HowFind extends Component {
  render() {
    return (
      <div className="fm-container">
        <p className="fm-webview-text">Wherever you see a Flamingo on the map, there is a scooter available to ride. When it’s time for Flamingo feeding, scooters are removed from the map.</p>
      </div>
    );
  }
}

export default HowFind;
