import React, { Component } from 'react';

class Bike extends Component {
  render() {
    return (
      <div className="fm-max">
        <div className="fm-max-image" style={{ backgroundImage: `url('https://storage.googleapis.com/flamingo-static/images/email/flamingo-5-wellington-email.jpg')` }}></div>
        <p className="fm-max-title">Flamingo Gen 5</p>
        <p className="fm-max-text">We are thrilled to be launching our latest generation Flamingo scooters in Wellington!</p>
        <p className="fm-max-text">Designed with your comfort, safety and style in mind, the Flamingo Gen 5 is set to redefine your scooter experience.</p>
        <div className="fm-how-to-safety-items">
          <div className="fm-how-to-safety-item">
            <div className="fm-how-to-safety-item-image" style={{ backgroundImage: `url('https://storage.googleapis.com/flamingo-static/images/support/gen5-3.png')` }}></div>
            <p className="fm-how-to-safety-item-message">Larger Front Wheel</p>
          </div>
        	<div className="fm-how-to-safety-item">
        		<div className="fm-how-to-safety-item-image" style={{ backgroundImage: `url('https://storage.googleapis.com/flamingo-static/images/support/gen5-4.png')` }}></div>
        		<p className="fm-how-to-safety-item-message">Turning Signals</p>
        	</div>
        	<div className="fm-how-to-safety-item">
        		<div className="fm-how-to-safety-item-image" style={{ backgroundImage: `url('https://storage.googleapis.com/flamingo-static/images/support/gen5-2.png')` }}></div>
        		<p className="fm-how-to-safety-item-message">Integrated Phone Holder</p>
        	</div>
        	<div className="fm-how-to-safety-item">
        		<div className="fm-how-to-safety-item-image" style={{ backgroundImage: `url('https://storage.googleapis.com/flamingo-static/images/support/gen5-1.png')` }}></div>
        		<p className="fm-how-to-safety-item-message">Improved Performance</p>
        	</div>
        </div>
        <p className="fm-max-text">To celebrate the launch, we're offering <b>10 minutes off</b> your ride! Simply redeem the voucher code <b>10NEW</b> in the Payment tab of the Flamingo app.</p>
        <p className="fm-max-text">Thanks for supporting a Kiwi owned and operated company!</p>
        <p className="fm-max-text">If you need help visit the "How to Ride" section of our app or simply email us at hello@flamingo.co.nz.</p>
      </div>
    );
  }
}

export default Bike;
