import React, { Component } from 'react';

class AddPromo extends Component {
  render() {
    return (
      <div className="fm-container">
        <p className="fm-webview-text">To add a promo code to your account, tap “Vouchers” under the “Payment” tab.</p>
      </div>
    );
  }
}

export default AddPromo;
