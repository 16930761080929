import React, { Component } from 'react';

class NewYears extends Component {
  render() {
    return (
      <div className="fm-max">
        <p className="fm-max-title">New Year's Eve 2023</p>
        <p className="fm-max-text">To celebrate New Year's Eve 2023, live music and fireworks will be on the Wellington Waterfront. This means there are a few changes to where you can use Flamingo.</p>
        <img className="fm-max-photo" alt="New Year's Eve 2023" src="https://storage.googleapis.com/flamingo-static/images/support/new-years.jpg" />
        <p className="fm-max-text">For health and safety reasons, there are restrictions on where you can ride or park your scooter/bike <b>on the waterfront between the Aukati Bridge (Whairepo Lagoon) and the Waitangi Park Bridge (Barnett Street)</b>.</p>
        <p className="fm-max-text"><b>12:30pm - 6:00pm</b>: You <b>cannot park</b> your scooter/bike in this area.</p>
        <p className="fm-max-text"><b>6:00pm - 1:30am</b>: You <b>cannot ride or park</b> your scooter/bike in this area.</p>
        <p className="fm-max-text">There are a number of temporary parking areas set up around the waterfront, just look for the blue [ P ] icon in the Flamingo app.</p>
        <p className="fm-max-text">Heading to waterfront? Get <b>5 minutes FREE</b>, just enter the code <b>NEWYEARS</b> from the "Payment" tab of the Flamingo app.</p>
        <p className="fm-max-end">Happy Riding,<br />The Flamingo Team</p>
        <br />
      </div>
    );
  }
}

export default NewYears;
