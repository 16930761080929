import React, { Component } from 'react';

class CubaDupa extends Component {
  render() {
    return (
      <div className="fm-max">
        <p className="fm-max-title">CubaDupa 2024</p>
        <p className="fm-max-text">CubaDupa is taking place in Wellington this weekend, this means there are a few changes to where you can use Flamingo.</p>
        <p className="fm-max-text">For health and safety reasons, <b>you cannot ride or park your scooter/bike within the festival</b>. These restrictions are in place from <b>9pm Friday to 3am Monday</b>.</p>
        <p className="fm-max-text">This area is highlighted on the map below, and in the Flamingo app:</p>
        <img className="fm-max-photo" alt="CubaDupa 2024" src="https://storage.googleapis.com/flamingo-static/images/support/cubadupa-2024.png?v2" />
        <p className="fm-max-text"><b>Heading to CubaDupa?</b> Get <b>10 minutes FREE</b>, just enter the code <b>CUBADUPA</b> from the "Payment" tab of the Flamingo app.</p>
        <p className="fm-max-text"><b>Where to Park?</b> Use the CubaDupa parking areas labeled with the blue 🅿️ icon in the Flamingo app.</p>
        <p className="fm-max-end">Happy Riding,<br />The Flamingo Team</p>
        <br />
      </div>
    );
  }
}

export default CubaDupa;
