import React, { Component } from 'react';

class Matariki extends Component {
  render() {
    return (
      <div className="fm-max">
        <p className="fm-max-title">Matariki Ahi Kā 2024</p>
        <p className="fm-max-text">Matariki Ahi Kā will be taking place on the Wellington Waterfront from Thursday 27th June to Sunday the 30th of June, this means there are a few changes to where you can use Flamingo.</p>
        <img className="fm-max-photo" alt="Matariki" src="https://storage.googleapis.com/flamingo-static/images/support/matariki.jpg" />
        <p className="fm-max-text">For health and safety reasons, <b>you cannot ride or park your scooter/bike anywhere on the waterfront between the Aukati Bridge (Whairepo Lagoon) and the Waitangi Park Bridge (Barnett Street)</b>. These restrictions are in place during the set up and pack down times, as well as during the experience:</p>
        <ul>
          <li className="fm-max-list">Thursday: 5pm - 9:30pm</li>
          <li className="fm-max-list">Friday: 5pm - 9:30pm</li>
          <li className="fm-max-list">Saturday: 5pm - 9:30pm</li>
          <li className="fm-max-list">Sunday: 5pm - 9:30pm</li>
        </ul>
        <p className="fm-max-text"><b>Parking:</b> There are a number of temporary parking areas set up around Matariki Ahi Kā, just look for the blue [ P ] icon in the Flamingo app.</p>
        <p className="fm-max-text"><b>Alternative Route:</b> Please refer to the green line on the map above for an alternative route to avoid Matariki Ahi Kā restrictions.</p>
        <p className="fm-max-text">Heading to Matariki Ahi Kā? Get <b>10 minutes FREE</b>, just enter the code <b>MATARIKI24</b> from the "Payment" tab of the Flamingo app.</p>
        <p className="fm-max-end">Happy Riding,<br />The Flamingo Team</p>
        <br />
      </div>
    );
  }
}

export default Matariki;
