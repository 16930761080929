import React, { Component } from 'react';

class Give20 extends Component {
  render() {
    return (
      <div className="fm-max">
        <div className="fm-top-image"></div>
        <p className="fm-max-title">Give 20, Get 20!</p>
        <p className="fm-max-text">If you have a friend, family member or colleague who is yet to take their first Flamingo ride, convince them to download the app and for a limited time only you will each receive 20 minutes of free riding!</p>
        <p className="fm-max-text">Share your unique referral code with as many friends as you like! It's time to get into the spirit of giving because you will get back 20 free minutes for each friend you refer.</p>
        <p className="fm-max-text">Find your referral code from the Payment section of the Flamingo app!</p>
        <p className="fm-max-end">Valid until 31 December 2020.</p>
      </div>
    );
  }
}

export default Give20;
