import React, { Component } from 'react';

class Vote23 extends Component {
  render() {
    return (
      <div className="fm-max">
        <p className="fm-max-title">2023 General Election</p>
        <p className="fm-max-text">Voting for the New Zealand 2023 General Election has officially opened!</p>
        <p className="fm-max-text">To help you vote we're giving you <b>15 minutes FREE</b> to get down to your voting place, just enter the code <b className="fm-vote-code">VOTE23</b> from the "Payment" tab of the Flamingo app.</p>
        <p className="fm-max-text">Tap the button below to view voting places near you.</p>
        <a className="fm-vote-button" href="https://map.vote.nz/voting-places" target="_blank" rel="noreferrer">Find Your Voting Place</a>
        <p className="fm-max-terms">Voucher valid for 15 minutes on Flamingo scooters and bikes. $1 unlocking fee still applies. Voucher expires on 14 October 2023.</p>
        <br />
      </div>
    );
  }
}

export default Vote23;
