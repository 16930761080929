import React, { Component } from 'react';

class NotWorking extends Component {
  render() {
    return (
      <div className="fm-container">
        <p className="fm-webview-text">If you encounter any issues with a scooter that could affect your safety, please stop riding immediately and report the problem by tapping the exclamation mark in the bottom left hand corner of the map. Our team will repair the scooter as soon as possible and you will not be charged for the ride.</p>
      </div>
    );
  }
}

export default NotWorking;
