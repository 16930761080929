import React, { Component } from 'react';

class Emergency extends Component {
  render() {
    return (
      <div className="fm-container">
        <p className="fm-webview-text">If you require immediate police or medical attention, please dial 111.</p>
        <p className="fm-webview-text">Once you and all involved are out of harm’s way, and the necessary emergency services have been contacted, please report the accident to Flamingo. Tap the exclamation mark in the bottom left hand corner of the map or call 0800 FLAMINGO to speak with a Flamingo representative.</p>
      </div>
    );
  }
}

export default Emergency;
