import React, { Component } from 'react';

class Contact extends Component {
  render() {
    return (
      <div className="fm-container">
        <p className="fm-webview-text">To become a Flamingo Feeder and charge our scooters, please visit our website at flamingoscooters.com/charge.</p>
      </div>
    );
  }
}

export default Contact;
