import React, { Component } from 'react';

class Station extends Component {
  render() {
    return (
      <div className="fm-max">
        <p className="fm-max-title">Railway Station Low Speed Zone</p>
        <p className="fm-max-text">The Wellington Railway Station forecourt is now a Low Speed Zone. This is to ensure the safety of pedestrians in this high foot traffic area.</p>
        <p className="fm-max-text">When you start a ride at the Wellington Railway Station your scooter will be limited to 15km/h while you're on the forecourt. Once you leave the forecourt your scooter will automatically return to a 25km/h speed limit. The same will apply when scooting to the station, as you enter the forecourt your scooter will automatically reduce its speed to 15km/h.</p>
        <p className="fm-max-text">The Low Speed Zone is outlined with the orange box below and in the Flamingo app.</p>
        <img className="fm-max-photo" alt="waterfront" src="https://storage.googleapis.com/flamingo-static/images/support/station-zone.jpg" />
        <p className="fm-max-end">If you have any questions, simply email us at hello@rideflamingo.co.nz.</p>
      </div>
    );
  }
}

export default Station;
