import React, { Component } from 'react';

class YearFree extends Component {
  render() {
    return (
      <div className="fm-max">
        <div className="fm-max-image" style={{ backgroundImage: `url('https://storage.googleapis.com/flamingo-static/images/email/win.jpg')` }}></div>
        <p className="fm-max-text">Need another excuse to ride Flamingo? For the month of November, get one entry into our draw for 1 year of free Flamingo riding every time you take a ride over 5 minutes!</p>
        <p className="fm-max-text">3 prizes to be won, winners receive 5,000 minutes to use over the next year. New & existing customers.</p>
        <p className="fm-max-text">Thanks for supporting a Kiwi owned and operated company!</p>
        <p className="fm-max-end">Happy Riding,<br/>The Flamingo Team</p>
      </div>
    );
  }
}

export default YearFree;
