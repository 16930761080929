import React, { Component } from 'react';

class EdenPark extends Component {
  render() {
    return (
      <div className="fm-max">
        <p className="fm-max-title">Coldplay</p>
        <p className="fm-max-text">Coldplay are performing at Eden Park tonight, which means there are a few changes to where you can use Flamingo.</p>
        <img className="fm-max-photo" alt="Eden Park" src="https://storage.googleapis.com/flamingo-static/images/admin/operation-eden-park-label-2.png" />
        <p className="fm-max-text">For health and safety reasons, scooters <b>cannot be ridden or parked anywhere within the surrounding streets of Eden Park</b>. This restricted zone is marked in red on the map above. Additionally, a 15 km/h speed limit applies in Kingsland and on Dominion Road.</p>
        <p className="fm-max-text">Heading to the concert? Please <b>use one of our designated parking areas</b> on New North Road or Dominion Road. Look for the blue 🅿️ icon in the Flamingo app to find these locations. Parking in these areas keeps pathways clear for the expected crowds.</p>
        <p className="fm-max-text"><b>Enjoy 10 minutes FREE!</b> Enter the code <b>SPHERES</b> in the "Payment" tab of the Flamingo app to get 10 minutes free for your ride.</p>
        <p className="fm-max-end">Safe Riding,<br />The Flamingo Team</p>
        <br />
      </div>
    );
  }
}

export default EdenPark;
