import React, { Component } from 'react';

class NeedHelmet extends Component {
  render() {
    return (
      <div className="fm-container">
        <p className="fm-webview-text">The Flamingo Terms of Service requires you to wear a helmet whenever you are riding a scooter.</p>
      </div>
    );
  }
}

export default NeedHelmet;
