import React, { Component } from 'react';

class ReportUnlocked extends Component {
  render() {
    return (
      <div className="fm-container">
        <p className="fm-webview-text">Please call 0800 FLAMINGO to speak with a Flamingo representative.</p>
      </div>
    );
  }
}

export default ReportUnlocked;
