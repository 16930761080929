import React, { Component } from 'react';

class Ruru extends Component {
  render() {
    return (
      <div className="fm-max">
        <div className="fm-ruru-image"></div>
        <p className="fm-max-title">New Ruru Parking Racks</p>
        <p className="fm-max-text">Wellington City Council is installing new e-scooter parking racks in 10 locations around the city, including:</p>
        <ul>
          <li className="fm-ruru-li">the waterfront – Whitmore Street, Queens Wharf (by Jervois Quay) and Taranaki Street</li>
          <li className="fm-ruru-li">Cuba Street at Ghuznee Street</li>
          <li className="fm-ruru-li">Cable Street at Tory Street (near Te Papa)</li>
          <li className="fm-ruru-li">Courtenay Place – near Taranaki Street and Cambridge Terrace</li>
          <li className="fm-ruru-li">Oriental Parade – near Waitangi Park, at Freyberg Pool and the Band Rotunda.</li>
        </ul>
        <p className="fm-max-text">The first has been installed at Taranaki Wharf and the others will be installed over the coming weeks. Remember to park your Flamingo in the racks where available!</p>
      </div>
    );
  }
}

export default Ruru;
