import React, { Component } from 'react';

class RoadSafetyWeek extends Component {
  render() {
    return (
      <div className="fm-max">
        <p className="fm-max-title">Road Safety Week</p>
        <p className="fm-max-text">This week is Road Safety Week! It's the perfect time to brush up on your road safety skills and refresh your knowledge of local rules and regulations. Check out our top tips for staying safe this week:</p>
        <div className="fm-max-item">
          <img className="fm-max-item-img" alt="Scooter" src="/assets/images/city/heart.svg" />
          <div className="fm-max-item-details">
            <p className="fm-max-item-title">Ride & Park Considerately</p>
            <p className="fm-max-item-text">Take care around pedestrians and park to the side of the path.</p>
          </div>
        </div>
        <div className="fm-max-item">
          <img className="fm-max-item-img" alt="Go Pass" src="/assets/images/city/scooter.svg" />
          <div className="fm-max-item-details">
            <p className="fm-max-item-title">Don't Double Ride</p>
            <p className="fm-max-item-text">Two riders = two scooters.</p>
          </div>
        </div>
        <div className="fm-max-item">
          <img className="fm-max-item-img" alt="Scooter" src="/assets/images/city/beer.svg" />
          <div className="fm-max-item-details">
            <p className="fm-max-item-title">Don't Drink and Ride</p>
            <p className="fm-max-item-text">If you wouldn't drive, you shouldn't ride.</p>
          </div>
        </div>
        <div className="fm-max-item">
          <img className="fm-max-item-img" alt="Scooter" src="/assets/images/city/traffic.svg" />
          <div className="fm-max-item-details">
            <p className="fm-max-item-title">Stay Safe at Intersections</p>
            <p className="fm-max-item-text">Red means stop, even on a scooter.</p>
          </div>
        </div>
        <div className="fm-max-item">
          <img className="fm-max-item-img" alt="Scooter" src="/assets/images/city/phone.svg" />
          <div className="fm-max-item-details">
            <p className="fm-max-item-title">Ride without Distractions</p>
            <p className="fm-max-item-text">Keep your phone in your pocket.</p>
          </div>
        </div>
        <p className="fm-max-text">Thanks for staying safe this Road Safety Week! Try these tips out on your next ride with <b>10 minutes FREE</b> by redeeming the code <b>RSW</b> from the Payment section of the Flamingo app.</p>
        {
          this.props.wellington &&
          <a className="fm-max-item-card" href="https://www.facebook.com/events/1066416071129408" target="_blank" rel="noreferrer">
            <p className="fm-max-item-card-title">Get 20 mins FREE on Saturday!</p>
            <p className="fm-max-item-card-text">Are you a Flamingo novice or pro? Come and test your skills this Saturday between 10am and 12pm at Waitangi Park and get 20 minutes free.</p>
            <p className="fm-max-item-card-link">Learn More...</p>
          </a>
        }
        <p className="fm-max-end">Safe Riding,<br/>The Flamingo Team</p>
      </div>
    );
  }
}

export default RoadSafetyWeek;
