import React, { Component } from 'react';

class UnlockMultiple extends Component {
  render() {
    return (
      <div className="fm-container">
        <p className="fm-webview-text">You can unlock up to 5 scooters at any time through one account/app. After unlocking your first scooter, simply tap "Add Ride", then scan in an additional scooter.</p>
        <p className="fm-webview-text">Unlocking fees and per minute rates apply to each scooter that is unlocked.</p>
        <p className="fm-webview-text">You can add and remove scooters as you please throughout your Group Ride. To end your rides, tap "End Rides", then select the rides you wish to end.</p>
        <p className="fm-webview-text">You are still able to use the same payment method on more than one account.</p>
      </div>
    );
  }
}

export default UnlockMultiple;
