import React, { Component } from 'react';

class Homegrown extends Component {
  render() {
    return (
      <div className="fm-max">
        <p className="fm-max-title">Homegrown 2024</p>
        <p className="fm-max-text">Homegrown is taking place on the Wellington Waterfront this Saturday the 16th of March, this means there are a few changes to where you can use Flamingo.</p>
        <p className="fm-max-text">For health and safety reasons, <b>you cannot ride or park your scooter/bike on the waterfront from the TSB Arena to Waitangi Park</b>. These restrictions are in place from <b>7am Saturday to 8am Sunday</b>.</p>
        <p className="fm-max-text">This area is highlighted on the map below, and in the Flamingo app:</p>
        <img className="fm-max-photo" alt="Homegrown 2024" src="https://storage.googleapis.com/flamingo-static/images/support/homegrown-2024.png" />
        <p className="fm-max-text"><b>Heading to Homegrown?</b> Get <b>20 minutes FREE</b>, just enter the code <b>HOMEGROWN</b> from the "Payment" tab of the Flamingo app.</p>
        <p className="fm-max-text"><b>Where to Park?</b> Use the Homegrown parking areas labeled with the blue 🅿️ icon in the Flamingo app.</p>
        <p className="fm-max-end">Happy Riding,<br />The Flamingo Team</p>
        <br />
      </div>
    );
  }
}

export default Homegrown;
