import React, { Component } from 'react';
import Loading from '../common/loading';

class Home extends Component {
  componentDidMount() {
    document.title = 'Flamingo Scooters';
    window.location = 'https://flamingoscooters.com/';
  }

  render() {
    return <Loading />;
  }
}

export default Home;
