import React, { Component } from 'react';

class Outage extends Component {
  render() {
    return (
      <div className="fm-max">
        <p className="fm-max-title">Network Outage - 1 Sep</p>
        <p className="fm-max-text">A network outage is currently impacting our service.</p>
        <p className="fm-max-text">We deeply apologise for the inconvenience this may be causing. Our team are working as fast as possible to restore the network.</p>
        <p className="fm-max-text">This issue is expected to be resolved by midday. You will recieve an email update once issue has been resolved.</p>
        <br />
      </div>
    );
  }
}

export default Outage;
