import React, { Component } from 'react';

class Max extends Component {
  render() {
    return (
      <div className="fm-max">
        <p className="fm-max-title">COVID-19 Safety</p>
        <p className="fm-max-text">Please be patient as our team work to sanitise scooters throughout the day. There are a number of additional steps we are taking to ensure the safety of everyone.</p>
        <div className="fm-max-item">
          <img className="fm-max-item-img" alt="Scooter" src="/assets/images/covid/distance.svg" />
          <div className="fm-max-item-details">
            <p className="fm-max-item-title">Social Distancing</p>
            <p className="fm-max-item-text">Follow social distancing guidelines when unlocking and parking scooters near others.</p>
          </div>
        </div>
        <div className="fm-max-item">
          <img className="fm-max-item-img" alt="Scooter" src="/assets/images/covid/wash.svg" />
          <div className="fm-max-item-details">
            <p className="fm-max-item-title">Personal Hygiene</p>
            <p className="fm-max-item-text">Wash hands before and after riding, or use hand sanitiser. Please do not ride a scooter if you are feeling unwell.</p>
          </div>
        </div>
        <div className="fm-max-item">
          <img className="fm-max-item-img" alt="Scooter" src="/assets/images/covid/cleaning.svg" />
          <div className="fm-max-item-details">
            <p className="fm-max-item-title">Enhanced Cleaning</p>
            <p className="fm-max-item-text">Our cleaning procedures have been enhanced with a special focus on high touch areas.</p>
          </div>
        </div>
        <div className="fm-max-item">
          <img className="fm-max-item-img" alt="Scooter" src="/assets/images/covid/scooter.svg" />
          <div className="fm-max-item-details">
            <p className="fm-max-item-title">Sanitisation Tracking</p>
            <p className="fm-max-item-text">Our systems are tracking the cleaning of all scooters to ensure they are fully sanitised at least once a day.</p>
          </div>
        </div>
        <div className="fm-max-item">
          <img className="fm-max-item-img" alt="Scooter" src="/assets/images/covid/person.svg" />
          <div className="fm-max-item-details">
            <p className="fm-max-item-title">Contact Tracing</p>
            <p className="fm-max-item-text">Our software allows contact tracing, in the low chance this is required.</p>
          </div>
        </div>
        <p className="fm-max-end">Please contact us if you have any questions in regards to our ongoing response to COVID-19. Please advise us immediately by calling 0800 FLAMINGO if you ride a scooter and then later test positive for COVID-19</p>
      </div>
    );
  }
}

export default Max;
