import React, { Component } from 'react';

class Christchurch extends Component {
  render() {
    return (
      <div className="fm-max">
        <p className="fm-max-title">Flamingo Christchurch</p>
        <p className="fm-max-text">To our Flamingo family,</p>
        <p className="fm-max-text">Unfortunately, Christchurch City Council decided our latest e-scooter permit application was unsuccessful, therefore we have relocated our Canterbury operations to the Waimakariri District.</p>
        <p className="fm-max-text">We would like to thank every single person who has joined us on our journey over the last 2 years in Christchurch. We have been overwhelmed by the support for a Kiwi owned e-scooter operator, from 300 scooters in September 2019 to 800 scooters in 2021 and over a quarter of a million rides.</p>
        <p className="fm-max-text">As a Kiwi born and bred company, we uphold our commitment to bringing the future of urban micro-mobility to New Zealand. We feel proud to be continuing to support the Canterbury region and excited to be operating in the Waimakariri District, including Rangiora, Kaiapoi, Woodend & Pegasus. We are also proud to continue operating in Wellington, our hometown, as the only locally-owned and operated e-scooter company.</p>
        <p className="fm-max-text">We hope to see you all again in the future. Until then, stay safe and look after one another!</p>
        <p className="fm-max-end">With love,<br/>The Flamingo Team</p>
      </div>
    );
  }
}

export default Christchurch;
