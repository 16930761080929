import React, { Component } from 'react';

class RoundTheBays extends Component {
  render() {
    return (
      <div className="fm-max">
        <p className="fm-max-title">Round the Bays 2024</p>
        <p className="fm-max-text">The Wellington Round the Bays is on Sunday the 18th of February, this means there are a few changes to where you can use Flamingo.</p>
        <p className="fm-max-text">For health and safety reasons, <b>you cannot ride or park your scooter/bike any where on the course</b>. These restrictions are <b>lifted from 1pm Sunday</b>.</p>
        <p className="fm-max-text">This area is highlighted on the map below, and in the Flamingo app:</p>
        <img className="fm-max-photo" alt="Round the Bays Track" src="https://storage.googleapis.com/flamingo-static/images/support/round-the-bays.png?y=2024" />
        <p className="fm-max-text">Taking part in Round the Bays? Get <b>20 minutes FREE</b>, just enter the code <b>RTB2024</b> from the "Payment" tab of the Flamingo app.</p>
        <p className="fm-max-text"><b>Heading to Round the Bays?</b> Remember to park your Flamingo away from the start area and clear of the track!</p>
        <p className="fm-max-text"><b>Heading back to the city?</b> A number of Flamingo's will be available from the north west corner of Kilbirnie Park! If you're heading back before 1pm, avoid the waterfront.</p>
        <p className="fm-max-end">Happy Riding,<br />The Flamingo Team</p>
        <br />
      </div>
    );
  }
}

export default RoundTheBays;
