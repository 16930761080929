import React, { Component } from 'react';

class PendingCharge extends Component {
  render() {
    return (
      <div className="fm-container">
        <p className="fm-webview-text">You may see a $1 pending charge on your bank statement as part of the card authorisation process. This is a temporary authorisation charge and it will disappear from your statement.</p>
      </div>
    );
  }
}

export default PendingCharge;
