import React, { Component } from 'react';

class Max extends Component {
  render() {
    return (
      <div className="fm-max">
        <div className="fm-max-image"></div>
        <p className="fm-max-title">Flamingo Scooter Safety</p>
        <p className="fm-max-text">Before you ride there are a few things you need to know...</p>
        <div className="fm-max-item">
          <img className="fm-max-item-img" alt="Kickstands" src="https://storage.googleapis.com/flamingo-static/images/training/plus-start.png" />
          <div className="fm-max-item-text-box">
            <p className="fm-max-item-title">New Kickstands</p>
            <p className="fm-max-item-text">Our new dual kickstands are more sturdy and stable. To raise the kickstand, <b>gently push the scooter forward</b> and ensure the kickstand flicks the whole way up.</p>
          </div>
        </div>
        <div className="fm-max-item">
          <img className="fm-max-item-img" alt="Braking" src="https://storage.googleapis.com/flamingo-static/images/training/plus-braking.png" />
          <div className="fm-max-item-text-box">
            <p className="fm-max-item-title">Always Test Brakes Before Riding</p>
            <p className="fm-max-item-text">Our new scooters have a manual <b>front and rear</b> brake. Make sure you test both brake levers before riding.</p>
          </div>
        </div>
        <div className="fm-max-item">
          <img className="fm-max-item-img" alt="No Step" src="https://storage.googleapis.com/flamingo-static/images/training/plus-no-step.png" />
          <div className="fm-max-item-text-box">
            <p className="fm-max-item-title">Foot Placement</p>
            <p className="fm-max-item-text">Keep both feet on the board for a safe ride. <b>Do not stand on the rear fender</b>.</p>
          </div>
        </div>
        <div className="fm-max-item">
          <img className="fm-max-item-img" alt="Kickstands" src="https://storage.googleapis.com/flamingo-static/images/training/plus-end.png" />
          <div className="fm-max-item-text-box">
            <p className="fm-max-item-title">Ending Your Ride</p>
            <p className="fm-max-item-text">At the end of your ride, lower the dual kickstand with your foot while <b>gently rolling the scooter backwards</b>.</p>
          </div>
        </div>
        <div className="fm-max-item">
          <img className="fm-max-item-img" alt="Helmet" src="https://storage.googleapis.com/flamingo-static/images/support/helmet.png" />
          <div className="fm-max-item-text-box">
            <p className="fm-max-item-title">Helmet</p>
            <p className="fm-max-item-text">Always wear your helmet! You can get a free helmet through the Flamingo app, just pay for shipping.</p>
          </div>
        </div>
        <p className="fm-max-end">Happy Riding! If you need any more help visit the "How to Ride" section of the app or email hello@flamingoscooters.co.nz.</p>
      </div>
    );
  }
}

export default Max;
