import React, { Component } from 'react';

class DunedinStadium extends Component {
  render() {
    return (
      <div className="fm-max">
        <p className="fm-max-title">All Blacks v England</p>
        <p className="fm-max-text">The All Blacks are playing England at Forsyth Barr Stadium tonight, Saturday 6th July, which means there are a few changes to where you can use Flamingo.</p>
        <img className="fm-max-photo" alt="Matariki" src="https://storage.googleapis.com/flamingo-static/images/support/dunedin-stadium-zones.png" />
        <p className="fm-max-text">For health and safety reasons, scooters <b>cannot be ridden or parked anywhere within Forsyth Barr Stadium, including the carpark and university plaza</b>. This area is outlined in red on the map above. A speed restriction of <b>15 km/h also applies to the area surrounding the stadium</b>.</p>
        <p className="fm-max-text">Heading to the game? Please make sure to <b>use one of the temporary parking areas</b> set up around Forsyth Barr Stadium, just look for the blue 🅿️ icon in the Flamingo app. This ensures the pathways remain clear for the expected crowds.</p>
        <p className="fm-max-end">Happy Riding,<br />The Flamingo Team</p>
        <br />
      </div>
    );
  }
}

export default DunedinStadium;
