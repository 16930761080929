import React, { Component } from 'react';

class WhenAvailable extends Component {
  render() {
    return (
      <div className="fm-container">
      <p className="fm-webview-text">Our current service hours vary across different cities.</p>
      <p className="fm-webview-text">Wellington: 24/7 with some area restrictions after 9pm on Friday and Saturday nights</p>
      <p className="fm-webview-text">Christchurch: 5am - midnight</p>
      <p className="fm-webview-text">You are unable to start a new ride outside of these hours but you can finish a ride you have already started. Whenever you see a Flamingo on the map, the scooter is available to ride.</p>
      </div>
    );
  }
}

export default WhenAvailable;
