import React, { Component } from 'react';

class CanTransport extends Component {
  render() {
    return (
      <div className="fm-container">
        <p className="fm-webview-text">Yes, provided the Flamingo is unlocked for the whole journey and stays within our service zone.</p>
      </div>
    );
  }
}

export default CanTransport;
